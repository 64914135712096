.container-inner-not-found {
  background: rgba(255, 28, 38, 0.12);
  padding: 17px 18px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 8px solid rgba(255, 28, 38, 0.04);
}
.error-code {
  color: #ff1c26;
  font-weight: 700;
}
.error-reason {
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  color: #07172f;
  width: 30%;
  text-align: center;
}
.error-btn-max-width {
  max-width: 400px;
  width: 100%;
}

@media (max-width: 760px) {
  .error-code {
    color: #ff1c26;
    font-weight: 600;
  }
  .error-reason {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.5;
    color: #07172f;
    width: 60%;
    text-align: center;
  }

  .error-btn-max-width {
    max-width: 70%;
  }
}
