.input-container {
  width: 500px;
  max-width: 100%;
  font-family: 'PlusJarkataRegular';
}
.input-label {
  display: block;
  color: rgba(32, 14, 50, 0.5);
  font-size: 15px;
}
.input-label-black {
  display: block;
  color: #24292e;
  font-size: 14px;
}
.app-input-wrapper {
  position: relative;
}
.error-message {
  position: absolute;
  top: 70px;
  color: red;
}
.input-icon {
  position: absolute;
  right: 20px;
  bottom: 50%;
}
.app-input {
  outline: none;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;
  height: 40px;
  padding: 0px 20px;
  font-size: 15px;
  border: 0.2px solid rgba(25, 0, 65, 0.26);
}
.borderless-app-input {
  outline: none;
  margin-top: 12px;
  margin-bottom: 30px;
  width: 100%;
  height: 40px;
  padding: 0px 22px;
  font-size: 15px;
  border: none;
  border-bottom: 2px solid rgba(25, 0, 65, 0.26);
  border-radius: 0px !important;
}
.ant-picker {
  border-radius: 0px !important;
  background-color: #fff !important;
  height: 45px !important;
  font-size: 15px !important;
  margin-top: 10px !important;
  margin-bottom: 30px !important;
  outline: none !important;
  border: none !important;
  border-bottom: 2px solid rgba(25, 0, 65, 0.26) !important;
  width: 500px;
}

.app-input:focus {
  border-color: #ab3882;
}

.has-error {
  border: 0.2px solid red;
}

.show {
  position: absolute;
  right: 25px;
  font-size: 15px;
  top: 18px;
  color: rgba(32, 14, 50, 0.4);
}
.ant-checkbox + span {
  padding-right: 0px !important;
  padding-left: 6px !important;
}
.naira-prefix {
  position: absolute;
  top: 20px;
  left: 8px;
}
.plusIcon {
  top: 20px;
  right: 2%;
}

.minusIcon {
  top: 20px;
  right: 15%;
}

@media screen and (max-width: 768px) {
  .input-container {
    width: 100% !important;
  }
}
