.dash-margin {
  margin-top: -100px;
}

.dash-padding {
  padding-left: 200px;
}

.dash-left {
  width: 45%;
}

.dash-right {
  width: 55%;
}

.dash-icon {
  height: 30px;
}

.woman-user {
  width: 90%;
}

.home-info-g {
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 20px;
}

.inf0-c-padding {
  padding-left: 200px;
  padding-right: 100px;
}

.k-feature-text {
  font-size: 1.2em;
}

.woman-user-area {
  column-gap: 40px;
}

.kfeature-header {
  font-size: 1.5em;
}

.big-feature-text {
  font-size: 2.2em;
}

.big-info-text {
  font-size: 2.2em;
}

.feature-text {
  font-size: 1.2em;
}

/*Media query*/
@media (max-width: 760px) {
  .dash-padding,
  .woman-user-area {
    padding-left: 0;
    padding-right: 0;
  }

  .dash-right {
    width: 100%;
    margin-bottom: 150px;
  }

  .dash-left {
    width: 100%;
  }

  .woman-user-container,
  .woman-user,
  .home-feature-text-area {
    width: 100%;
  }

  .home-feature {
    padding-left: 20px;
    padding-right: 0px;
  }

  .home-feature-text-area {
    margin-top: 40px;
  }

  .home-info-g {
    grid-template-columns: 1fr;
    padding: 0px 20px;
  }

  .big-feature-text {
    margin-top: 30px;
    text-align: left;
    font-size: 1.6em;
  }

  .big-info-text {
    margin-top: 30px;
    text-align: center;
    font-size: 1.6em;
  }

  .feature-text {
    text-align: left;
  }

  .dash-flex-container {
    flex-direction: column-reverse;
  }

  .k-feature-text {
    font-size: 1.2em;
  }

  .kfeature-header {
    font-size: 1.5em;
  }
}
