.back-card {
  /* margin-top: -21px; */
  transform: rotateY(90deg);
  overflow: hidden;
}

.link-l {
  max-width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.front-card {
  position: absolute;
  overflow: hidden;
}

@keyframes twirl {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}

.lo:hover .front-card {
  animation: twirl 0.3s ease-in-out forwards;
}

.lo:hover .back-card {
  animation: twirl 0.3s 0.3s ease-out forwards reverse;
}
