.mock-wrapper {
  padding: 50px;
  max-width: 1400px;
  margin: 0 auto;
}

.left-side-mock {
  row-gap: 60px;
}

.right-side-mock {
  width: calc(100% - 250px);
  padding-left: 30px;
}
