.navigation {
  z-index: 10;
}
.nav-links > a {
  color: var(--color-text);
  font-size: 1.18em;
  text-decoration: none;
  transition: color 0.12s ease;
}

.active-link {
  color: var(--color-action) !important;
}

.has-bg .nav-links > a {
  color: white;
}

.nav-bg {
  background-color: white;
  transition: background-color 0.12s ease;
}
.nav-no-bg {
  background: none;
  transition: background 0.12s ease;
}
.nav-links > a:hover {
  color: var(--color-action);
  transition: color 0.12s ease;
}
.nav-links.always-active > a {
  color: white;
  opacity: 1;
  padding: 7px 20px;
  background-color: var(--color-action);
  border-radius: 5px;
  transition: opacity 0.12s ease;
}
.nav-links.always-active > a:hover {
  opacity: 0.7;
  transition: opacity 0.12s ease;
}
.nav-open {
  animation: fadeIn 0.5s ease forwards;
}

.nav-open > .main-portion {
  animation: slidein 0.5s ease forwards;
}
.nav-open > .main-portion > div {
  animation: appear 0.5s ease forwards;
}

.nav-close {
  animation: fadeOut 0.5s ease forwards;
}
.nav-close > .main-portion {
  animation: slideout 0.5s ease forwards;
}

.nav-close > .main-portion > div {
  display: none;
}

@keyframes slidein {
  from {
    width: 0;
  }

  to {
    width: 80%;
  }
}

@keyframes slideout {
  from {
    width: 80%;
    display: block;
  }

  to {
    width: 0;
    display: none;
  }
}

@keyframes fadeIn {
  from {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
  }

  to {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    width: 100%;
    display: block;
  }

  to {
    width: 0;
    display: none;
  }
}

/*Media query*/
@media (max-width: 760px) {
  .icon {
    font-size: 20px;
  }
  .contents {
    flex-direction: column;
  }
  .navigation {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .content {
    top: 0;
    left: 0;
    bottom: 0;
    padding: 100px 40px;
  }
  .whole-area,
  .main-portion {
    top: 0;
    left: 0;
    height: 100%;
  }
  .main-portion > content {
    padding: 100px 40px;
  }
  .nav-links {
    margin-bottom: 20px;
  }
  .nav-links a {
    color: white;
  }
  .nav-links.always-active {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
