.dashboard-wrapper {
  display: flex;
  flex-direction: row;
}

.dashboard-header {
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  height: 93px;
  padding: 0px 40px;
  position: sticky;
  top: 0;
  z-index: 2;
  /* border-bottom: 0.5px solid rgba(32, 14, 50, 0.25); */
}

.dashboard-layout {
  width: calc(100% - 250px);
  overflow-y: scroll;
}

.dashboard-layout::-webkit-scrollbar {
  width: 0.1px;
  height: 0.1px;
}

.font-size-14em {
  font-size: 1.4em;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px;
  overflow: hidden;
}
.font-size-13em {
  font-size: 1.2em;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px;
  overflow: hidden;
}
.font-size-1em {
  font-size: 1em;
}
.font-size-13 {
  font-size: 13px;
}

.generate-payment {
  background: #ed2b30;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  padding: 0px 20px;
  height: 3.5em;
  display: flex;
  align-items: center;
}

.notify-card {
  background: linear-gradient(136.67deg, #ae207b 8.34%, #e20e17 45.92%, #f79420 95.26%);
  width: 70px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.max-height-700 {
  max-height: 700px;
}
.dashboard-flex {
  display: flex;
}
.dashboard-card {
  width: 48%;
}
.cashier-wrapper .ant-table-cell {
  cursor: default;
}
.mobile-chart-wrapper {
  display: none;
}
.add-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 12rem;
}
.fade-in-text {
  animation: fadeInText ease 1s;
}

.red-circle {
  background: #fee6e7;
  border-radius: 50%;
  width: 15%;
  height: 35%;
  padding: 5px;
}
.alternate-account {
  border-bottom: 0.02px solid rgba(32, 14, 50, 0.15);
  border-radius: 5px;
  background-color: var(--color-primary);
}

/* style input here */
#file-upload::before {
  content: '';
}

@media (max-width: 760px) {
  .dashboard-wrapper {
    flex-direction: column;
    background: #fff !important;
  }
  .dashboard-layout {
    width: calc(100%);
  }
  .dashboard-header {
    border-bottom: 0.5px solid rgba(32, 14, 50, 0.25);
    padding: 0px 15px;
  }
  .dashboard-flex-wrapper {
    display: block;
  }
  .dashboard-flex {
    width: 100%;
  }
  .bank-details-card,
  .total-transaction-card {
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  }
}

@media screen and (max-width: 991px) {
  .cashier-wrapper {
    width: 100%;
  }
  .chart-wrapper {
    display: none;
  }
  .mobile-chart-wrapper {
    display: flex;
    width: 100%;
  }
  .dashboard-flex {
    display: block;
  }
  .dashboard-card {
    width: 100%;
    margin-bottom: 10px;
  }
}

@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
