footer {
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: #000000;
}
.footer-links > a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.12s ease;
}
.social-links {
  text-decoration: none;
}
.footer-divider {
  border: 0.5px solid rgba(32, 14, 50, 0.25);
}
.social-links > a {
  opacity: 1;
  transition: opacity 0.12s ease;
}

.social-links > a:hover {
  opacity: 0.6;
  transition: opacity 0.12s ease;
}
.footer-links > a:hover {
  color: var(--color-action);
  transition: color 0.12s ease;
}
.footer-social-links {
  color: var(--color-action);
}

.footer-text {
  max-width: 70%;
}
.livevideo-prompt-container {
  background-color: #ae207c1a;
  padding: 10px 15px;
  color: #ae207c;
  border-radius: 5px;
}

/*Media query*/
@media (max-width: 760px) {
  .vpay-links {
    align-items: center;
  }
  .vpay-links li {
    margin-bottom: 50px;
  }
  footer {
    padding-bottom: 20px;
    padding-top: 100px;
  }
  .footer-text {
    max-width: 100%;
  }
  .social-link-c {
    display: flex;
    flex-direction: column-reverse;
  }
}
