.dropdown-label {
  display: block;
  color: rgba(32, 14, 50, 0.5);
  font-size: 17px;
}

.dropdown-label-black {
  display: block;
  color: rgb(0, 0, 0);
  font-size: 14px;
}

.ant-select {
  border: 0.2px solid rgba(25, 0, 65, 0.26) !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  height: 45px !important;
  font-size: 15px !important;
  margin-top: 10px !important;
  margin-bottom: 30px !important;
  outline: none !important;
}

.ant-select-borderless.unique-borderless {
  border-radius: 0px !important;
  background-color: #fff !important;
  height: 45px !important;
  font-size: 15px !important;
  margin-top: 10px !important;
  margin-bottom: 30px !important;
  outline: none !important;
  border: none !important;
  border-bottom: 2px solid rgba(25, 0, 65, 0.26) !important;
  width: 500px;
}

.has-error-1 {
  border: 1px solid red !important;
}

.ant-select-selector:focus {
  border: #ab3882 !important;
}

.dropdown {
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  display: none;
}

.dropdown-body.open {
  display: block;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}

/* .my-select-container .ant-select .ant-select-selector {
  border-radius: 100px;
} */

@media screen and (max-width: 768px) {
  .dropdown-container {
    width: 100%;
  }
}
