.store-button {
  width: 120px;
  background-position: center;
  transition: background 0.8s;
}
.store-button:hover {
  background: rgba(255, 201, 40, 0.2) radial-gradient(circle, transparent 1%, rgba(255, 201, 40, 0.2) 1%) center/15000%;
}

.store-button:active {
  background-color: rgba(255, 201, 40, 0.2);
  border-radius: 5px;
  background-size: 100%;
  transition: background 0s;
}

.store-button:focus {
  outline: none;
}

.store-icon {
  width: 100%;
  height: 100%;
}
