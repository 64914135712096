.disabled-popupBtn {
  cursor: not-allowed;
  background-color: rgb(229, 229, 229) !important;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  border-radius: 5px;
  padding: 10px;
  width: 120px;
}
.small {
  color: #ed2b30;
  display: flex;
  justify-content: center;
}
.font-s16 {
  font-size: 1em !important;
}

/* dropdown styles here*/

.bulk-group-wrapper {
  width: 100%;
  max-height: 50vh;
  overflow-y: scroll;
  padding: 20px;
  position: absolute;
  z-index: 2;
  box-shadow: 3px 18px 26px -6px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 3px 18px 26px -6px rgb(0 0 0 / 20%);
  -moz-box-shadow: 3px 18px 26px -6px rgba(0, 0, 0, 0.2);
}
.bulk-dropdown {
  width: 350px;
  padding: 35px 20px;
  border-bottom: 0.02px solid rgba(32, 14, 50, 0.15);
  border-radius: 5px;
  height: 127.4px;
}
.gradient-bg-color {
  background: linear-gradient(90deg, #ab3882 -2.86%, #ff1c26 60.36%, #f79420 114.82%);
}

/* dropdown styles here*/
.dropdown-arrow {
  color: var(--color-page-background);
  font-size: 1.3rem;
}
