/* .page-header{
    top: -10px;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 20px 0;
} */

.page-header-text {
  font-size: 40px;
}

.terms-wrapper {
  padding-top: 70px;
}

/*Media query*/
@media (max-width: 760px) {
  .page-header-text {
    font-size: 2em;
    line-height: 1.3;
  }

  .terms-wrapper {
    padding-top: 80px;
  }
}
