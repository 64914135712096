.rounded-bg {
  padding: 16px;
  background-color: rgba(255, 28, 38, 0.1);
  width: 55px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.arrow-down {
  transition: 0.3s ease;
  transform: rotate(180deg);
}
.slide-up {
  animation: slide-up 0.2s linear;
}
.options {
  z-index: 90;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.padding5 {
  padding: 5px;
}
.salaryColor {
  background-color: #f7941f;
}
.addBg {
  background-color: #ff1c261a;
}
.phoneColor {
  background-color: #3003e4;
}
.options-content {
  border: 1px solid #cfcfd0;
  border-radius: 8px;
  padding: 10px 7px;
  background-color: #ffffff;
}

.linebreak {
  border-bottom: 2px solid #19004126;
}
.overlay {
  background: linear-gradient(90deg, #ab3882 -2.86%, #ff1c26 60.36%, #f79420 114.82%);
  background-position: right;
  height: 180px;
  border-radius: 2px;
}
.custom-confirm-details {
  height: 150px;
  border-radius: 5px;
  width: 90%;
  margin: 0 auto;
  position: relative;
  top: -5rem;
  background-color: white;
}
.product-camera-bg {
  background-color: #f7f7f7;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.roles-user-bg {
  background-color: #fff0fa;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.greenCheckBg {
  padding: 20px;
  background-color: #e5ffed;
  border-radius: 50%;
}

.rc-virtual-list-holder-inner .ant-select-dropdown {
  z-index: 600 !important;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
