.home-about-image {
  width: 170%;
  height: 550px;
  border-radius: 10px;
}
.play-button {
  width: 170px;
  height: 170px;
  background-color: rgba(255, 255, 255, 0.68);
  border-radius: 50%;
}

.svg-inline--fa.fa-play.fa-w-14.play {
  height: 65px;
  width: 75px;
}

.home-about-text {
  padding-right: 100px;
  font-size: 1.2em;
}

.home-about-link {
  text-decoration: underline;
}
.home-about-link:hover {
  color: var(--color-action);
  text-decoration: underline;
}

.home-about-header-text {
  font-size: 2.2em;
}

.home-about-left {
  width: 50%;
}
.home-about-left > img {
  width: 100%;
}
.home-about-right {
  width: 50%;
}
.home-about-mobile {
  display: none;
}

/*Media query*/
@media (max-width: 760px) {
  .home-about-left {
    display: none;
  }
  .home-about-left,
  .home-about-right {
    width: 100%;
  }
  .home-about-text {
    padding-right: 0;
    text-align: left;
  }
  .home-about-right {
    margin-top: 40px;
    text-align: center;
  }
  .home-about-link {
    margin-bottom: 50px;
  }
  /* .second-about-text{
        display: none;
    } */
  .home-about-mobile {
    display: block;
    width: 100%;
  }
  .home-about-header-text {
    font-size: 1.6em;
    text-align: left;
  }
}
