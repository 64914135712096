.merchant-features-background {
  padding: 50px 150px 0px 200px;
}

.feature-text-font {
  font-size: 1.28em;
}

.feature-content-font {
  font-size: 1.2em;
}
.feature-title {
  font-size: 2.1rem;
}

.mobile-feature-title {
  display: none;
}

.feature-max-width {
  max-width: 300px;
}

.m-feature-align {
  align-items: flex-start;
}

.m-feature-text {
  text-align: start;
}

/*Media query*/
@media (max-width: 760px) {
  .merchant-features-background {
    flex-direction: column-reverse;
    padding: 0px 20px 0px 10px;
  }
  .merchant-feature-grid-temp-col {
    grid-template-columns: 1fr;
  }
  .feature-text-font {
    font-size: 17px;
  }
  .feature-content-font {
    font-size: 17px;
  }
  .feature-title {
    display: none;
  }
  .mobile-feature-title {
    display: block;
    font-size: 1.554em;
  }
  .m-feature-align {
    align-items: center;
  }
  .m-feature-text {
    text-align: center;
  }
}
