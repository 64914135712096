.home-app-area {
  background: #27143b;
  padding: 80px 100px;
  border-radius: 10px;
  overflow: hidden;
  padding: 5vh 5vw;
}

.home-app-text-area {
  width: 60%;
}
.h-app-text-area {
  width: 50%;
}

.area-container {
  display: flex;
  align-items: center;
}

.home-app-image {
  top: -0px;
  right: 170px;
  height: 100%;
}

.spiral-rope {
  top: 0px;
  right: -30px;
}

.home-app-text {
  padding-right: 60px;
  font-size: 1.2em;
}
.h-app-text {
  padding-right: 60px;
  font-size: 1.2em;
  max-width: 450px;
}

/*Media query*/
@media (max-width: 760px) {
  .home-app-area-container,
  .home-app-text {
    padding: 0;
  }
  .home-app-text-area {
    width: 100%;
    padding: 20px;
  }

  .h-app-text-area {
    width: 100%;
    text-align: center;
    padding: 0px 20px;
  }
  .h-app-text {
    max-width: 100%;
  }

  .home-app-area {
    text-align: center;
    padding: 0px;
    border-radius: 0;
  }

  .home-app-text {
    font-size: 17px;
  }

  .app-store-button {
    justify-content: center;
  }
  .app-area-btn {
    font-size: 17px;
  }
  .spiral-rope {
    display: none;
  }
  .frag-space > div {
    display: none;
  }

  .all-platform {
    font-size: 2em;
  }
  .store-links-wrapper {
    justify-content: center;
  }

  .area-container {
    flex-direction: column;
  }

  .small-hand {
    margin-left: auto;
  }
}
